<script>
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import last from 'lodash/last';
import conditionalGroupReset from '@/mixins/conditionalGroupReset';
import { mapState } from 'vuex';

export default {
	mixins: [conditionalGroupReset],
	props: {
		label: String,
		options: {
			type: Array,
			default() {
				return [];
			},
		},
		other: Boolean,
		value: Array,
		id: String,
		testid: String,
		toolTipText: String
	},
	data() {
		return {
			localOtherChecked: false,
		};
	},
	computed: {
		...mapState(['currentRoleLevel']),
		knownChecked() {
			return intersection(this.value, this.optionValues);
		},
		otherValue() {
			return last(difference(this.value, this.optionValues));
		},
		showOther() {
			return this.other && (this.localOtherChecked || !!this.otherValue);
		},
		normalizedChecked() {
			return this.showOther
				? [...this.knownChecked, 'Other']
				: this.knownChecked;
		},
		normalizedOptions() {
			return this.other ? [...this.options, 'Other'] : this.options;
		},
		optionValues() {
			return this.options.map((option) =>
				option.value ? option.value : option
			);
		},
		isUserSeparatingParty(){
			return this.currentRoleLevel.roleAssociated === 'party'
		}
	},
	methods: {
		checkedChange(value) {
			const known = intersection(value, this.optionValues);
			const otherChecked = value.includes('Other');
			const otherValue = otherChecked ? this.otherValue : undefined;

			this.localOtherChecked = otherChecked;
			this.emitChange(known, otherValue);
		},
		emitChange(checked, other) {
			this.$emit('input', other ? [...checked, other] : checked);
		},
		otherChange(value) {
			this.emitChange(this.knownChecked, value);
		},
	},
};
</script>

<template>
	<b-form-group>
		<template v-slot:label>
			<span>{{ label }}</span>
			<b-icon-info-circle
				v-if="toolTipText && isUserSeparatingParty"
				v-b-tooltip="toolTipText"
				class="ml-2">
			</b-icon-info-circle>
		</template>
		<b-form-checkbox-group
			:id="id"
			stacked
			:options="normalizedOptions"
			:checked="normalizedChecked"
			@change="checkedChange"
			:data-testid="testid"
		/>
		<b-form-input
			v-if="showOther"
			class="mt-2"
			:value="otherValue"
			@change="otherChange"
			:data-testid="testid+'-other-value'"
		/>
	</b-form-group>
</template>
:
