<script>
import Datepicker from 'vuejs-datepicker';
import allProvinces from 'provinces';
import filter from 'lodash/filter';
import conditionalGroupReset from '@/mixins/conditionalGroupReset';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import agreementValidation from '@/mixins/agreementValidation'
import { mapState } from 'vuex';

const provinces = filter(allProvinces, { country: 'CA' });

export default {
	components: {
		Datepicker,
	},
	mixins: [conditionalGroupReset,validationMixin,agreementValidation],
	props: {
		agreementData: Object,
		province: Boolean,
		date: Boolean,
		dollar: Boolean,
		label: String,
		partner: Boolean,
		placeholderLabel: Boolean,
		percentage: Boolean,
		placeholder: String,
		question: String,
		inputCols: String,
		testid:String,
		value: [String, Number, Date, Boolean],
		isExclusion: Boolean,
		mobileWidth: String,
		disabled:Boolean,
		isValidationOn: Boolean,
		toolTipText:String
	},
	data() {
		return {
			checkValue: undefined,
			localValue: undefined,
			provinces,
			currencyValue: this.value != null ? (isNaN(parseFloat(this.value)) ? "" : parseFloat(this.value)) : undefined,
		};
	},
	computed: {
		...mapState(['currentRoleLevel']),
		showInputs() {
			return !this.question || this.checkValue === true;
		},
		isUserSeparatingParty(){
			return this.currentRoleLevel.roleAssociated === 'party'
		},
	},
	watch: {
		localValue() {
			this.$emit('input', this.localValue);
		},
		checkValue(val) {
			if (val === false) {
				this.$emit('input', val);
			} else {
				this.$emit('input', this.localValue);
			}
		},
		value() {
			this.updateValue();
		},
		currencyValue(){
			this.updateCurrencyValue();
		}
	},
	mounted() {
		this.updateValue();
	},
	methods: {
		updateValue() {
			if (this.question) {
				if (this.value === false || this.value === undefined) {
					this.checkValue = false;
					this.localValue = undefined;
				} else {
					this.checkValue = true;
					this.localValue = this.value;
				}
			} else {
				this.localValue = this.value;
			}
		},
		updateCurrencyValue() {
            if (this.question) {
                if (this.currencyValue === false || this.currencyValue === undefined) {
                    this.checkValue = false;
                    this.localValue = undefined;
                } else {
                    this.checkValue = true;
                    this.localValue = this.currencyValue;
                }
            } else {
                this.localValue = this.currencyValue;
            }
        },
		validateState() {
			const { $dirty, $error } = this.$v.localValue;
			return $dirty ? !$error : null;
		},
		triggerValidation(){
			this.$v.$touch()
		}
	},
	validations:{
		localValue:{
			required
		}
	}
};
</script>

<style>

.input-group{
	flex-wrap: nowrap !important;
}
</style>

<template>
	<div>
		<b-form-checkbox v-if="question" v-model="checkValue" :data-testid="testid">{{
			question
		}}</b-form-checkbox>
		<b-form-group
			v-if="showInputs"
			:label="label"
			:label-class="placeholderLabel ? 'sr-only' : ''"
			:class="{'field-required-group': isValidationOn}"
		>
			<b-row no-gutters>
				<b-col  :class="'col-sm-' + inputCols + ' '+'col-'+ mobileWidth">
					<b-input-group v-if="dollar" prepend="$">
						<InputNumber
							v-model="currencyValue"
							mode="decimal"
							:min-fraction-digits="2"
							:data-testid="testid"
							:placeholder="placeholder"
							pattern="[0-9]*" 
							inputmode="numeric"
							:class="[testid.includes('liability-exclusion') ? 'mobile-style' : '',(isExclusion && (!testid.includes('liability-exclusion'))) ? 'exclusion-amount': '']"
							:disabled="disabled"
						/>
							<!-- :class="isExclusion && 'exclusion-amount' " -->
						<b-icon-info-circle class="ml-2 mt-2"
							v-if="toolTipText && isUserSeparatingParty"
							v-b-tooltip="toolTipText">
						</b-icon-info-circle>
					</b-input-group>
					<b-input-group v-else-if="percentage" append="%">
						<b-form-input v-model="localValue" type="number" :data-testid="testid" pattern="[0-9]*" inputmode="numeric" :disabled="disabled"/>
					</b-input-group>
					<datepicker
						v-else-if="date"
						v-model="localValue"
						wrapper-class="text-dark"
						:data-testid="testid"
						input-class="form-control"
						:disabled="disabled"
					/>
					<partner-choice
						v-else-if="partner"
						v-model="localValue"
						:data-testid="testid"
						:agreement-data="agreementData"
						:disabled="disabled"
					/>
					<b-form-select
						v-else-if="province"
						v-model="localValue"
						:options="provinces"
						text-field="name"
						:data-testid="testid"
						value-field="short"
						:disabled="disabled"
					/>
					<b-form-input
						v-else
						v-model="$v.localValue.$model"
						:placeholder="placeholderLabel ? label : placeholder"
						:data-testid="testid"
						:disabled="disabled"
						:state="isValidationOn? validateState():null"
					/>
					<div
						v-if="$v.localValue.$error && isValidationOn"
						class="invalid-feedback d-block col-12"
					>
						This is a required field.
					</div>
				</b-col>
				<b-icon-info-circle class="ml-2 mt-2"
					v-if="toolTipText && !dollar && isUserSeparatingParty"
					v-b-tooltip.html="toolTipText">
				</b-icon-info-circle>
			</b-row>
		</b-form-group>
		<slot v-if="showInputs" />
	</div>
</template>
<style>
.mobile-style > .p-inputtext{
	@media (max-width: 391px) {
		width: 5.313rem;
	}
	@media (min-width: 391px) and (max-width: 480px) {
		width: 5.938rem;
	}
}
</style>