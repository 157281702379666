<script>
import { mapState } from 'vuex';

export default {
	model: {
		prop: 'checked',
	},
	props: {
		label: String,
		options: Array,
		checked: Array,
		testid:String,
		id:String,
		disabled: Boolean,
		toolTipText:String,
	},
	computed: {
		...mapState(['currentRoleLevel']),
		isUserSeparatingParty(){
			return this.currentRoleLevel.roleAssociated === 'party'
		}
	},
	methods: {
		emitInput(checked) {
			this.$emit('input', checked);
		},
	},
};
</script>

<template>
	<b-form-group :label="label" :disabled="disabled">
		<template v-slot:label>
			<span>{{ label }}</span>
			<b-icon-info-circle
				v-if="toolTipText && isUserSeparatingParty"
				v-b-tooltip.html="toolTipText"
				class="ml-2">
			</b-icon-info-circle>
		</template>
		<b-form-checkbox-group stacked :checked="checked" @input="emitInput">
			<div v-for="(option, index) in options" :key="index">
				<div class="d-flex align-items-center">
					<b-form-checkbox :value="option.value" :data-testid="testid+index" :id="id+index">{{ option.value }}</b-form-checkbox>
					<b-icon-info-circle
						v-if="option.tooltip && isUserSeparatingParty"
						v-b-tooltip.html="option.tooltip"
						class="ml-2"
						>
					</b-icon-info-circle>
				</div>
				
				<conditional-group
					v-if="$slots[option.value]"
					:condition="checked && checked.includes(option.value)"
					class="my-2"
					:testid="testid"
				>
					<slot :name="option.value" />
				</conditional-group>
			</div>
		</b-form-checkbox-group>
	</b-form-group>
</template>
:
