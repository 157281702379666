<script>
import Vue from 'vue';
import Navigation from './components/Navigation';
import { mapState } from 'vuex';
import Fragment from 'vue-fragment';
import Intercom from './Intercom.vue'; 

Vue.use(Fragment.Plugin);
export default {
	components: { Navigation, Intercom },
	computed: {
		...mapState(['user']),
	},
	methods: {
		initializeChatBot(){
			this.$root.$emit('appendIntercomScript');
		},
		setInitialDataOnLoad(){
			this.$store.dispatch('getAllRoleLevels')
		},
        handleLogoutEvent(event) {
            if (event.key === 'logout') {
                this.$router.push({ path: '/auth' });
                location.reload();
			}
		}
	},
	mounted(){
		this.initializeChatBot()
		this.setInitialDataOnLoad();
		window.addEventListener('storage', this.handleLogoutEvent);
	},
    beforeDestroy() {
        window.removeEventListener('storage', this.handleLogoutEvent);
    }
};
</script>

<template>
	<div id="app">
		<intercom/>
		<navigation :user="user" />
		<router-view />
	</div>
</template>

<style>
#app .form-group {
	margin-bottom: unset;
}

.app-input-group .input {
	margin: 0.1em;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.intercom-lightweight-app-launcher{
	bottom: 4.5rem !important;
}
</style>
